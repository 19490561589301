import React, { useState, useEffect } from 'react';
import styles from '../styles/ExperienceForm.module.css';
import { getSessionToken } from '../utils/Auth';

const ExperienceForm = ({ experience, onValidate, isEdit = false, isLast = true }) => {
    console.log("ExperienceForm", experience);
    const [formData, setFormData] = useState({
        title: experience.title || '',
        duration: Number(experience.duration) || 0,
        description: experience.description || '',
        company: experience.company || '',
        hardskills: experience.hardskills,
        study: false,
        experience_id: experience.experience_id
    });
    const sessionKey = getSessionToken();

    // If the experience object contains another experience object, make it a single object
    if (experience.experience) {
        experience = experience.experience;
    }


    useEffect(() => {
        setFormData({
            title: experience.title || '',
            duration: Number(experience.duration) || 0,
            description: experience.description || '',
            company: experience.company || '',
            hardskills: experience.hardskills,
            study: false,
            experience_id: experience.experience_id
        });
    }, [experience]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const isRegistered = localStorage.getItem('isRegistered') === 'true';
        if (!isRegistered) {
            // If the user is not registered, store the experience in localStorage
            const experiences = JSON.parse(localStorage.getItem('experiences')) || [];
            experiences.push(formData);
            localStorage.setItem('experiences', JSON.stringify(experiences));
        } else {
            // If the user is registered, send the experience to the API
            if (isEdit) {
                const response = await fetch('https://api.wanteed-job.com/experience/edit', {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'session-key': sessionKey
                    },
                    body: JSON.stringify(formData)
                });
                if (response.ok) {
                    //const newExperience = await response.json();
                } else {
                    console.error('Failed to edit experience');
                }
            } else {
                try {
                    const response = await fetch('https://api.wanteed-job.com/experience/add', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'session-key': sessionKey
                        },
                        body: JSON.stringify(formData)
                    });
                    if (response.ok) {
                        //const newExperience = await response.json();
                    } else {
                        console.error('Failed to add experience');
                    }
                } catch (error) {
                    console.error('Error adding experience:', error);
                }
            }
        }
        onValidate();
        console.log("Called onValidate");
    };

    const handleAddHardskill = (e) => {
        const skill = e.target.value;
        if (skill) {
            setFormData(prevState => ({
                ...prevState,
                hardskills: [...prevState.hardskills, skill]
            }));
            e.target.value = '';
        }
    }

    return (
        <form className={styles["form"]} onSubmit={handleSubmit}>
            <div className={styles["form-group"]}>
                <label className={styles["label"]}>Titre :</label>
                <input type="text" name="title" value={formData.title} onChange={handleChange} required placeholder='Responsable Marketing' />
            </div>
            <div className={styles["form-group"]}>
                <label>Durée<br />(en mois) :</label>
                <input type="number" name="duration" value={formData.duration} onChange={handleChange} required />
            </div>
            <div className={styles["form-group"]}>
                <label>Entreprise / école :</label>
                <input type="text" name="company" value={formData.company} onChange={handleChange} required />
            </div>
            <div className={styles["form-group"]}>
                <label style={{ width: '55%' }}>Cocher s'il d'experience vécue pendant vos études :</label>
                <input style={{ width: '45%', height: '30px' }} type="checkbox" name="study" checked={formData.study} onChange={(e) => setFormData(prevState => ({ ...prevState, study: e.target.checked }))} />
            </div>
            <div className={styles["form-description"]}>
                <label>Description:</label>
                <textarea name="description" value={formData.description} onChange={handleChange} required placeholder='Développement de la stratégie marketing...'></textarea>
            </div>
            <div className={styles["form-skill"]}>
                <div className={styles["skills-container"]}>
                    {formData.hardskills && formData.hardskills.map((skill, index) => (
                        <div key={index} className={styles["skill-tag"]}>
                            <span>#{skill}</span>
                            <span className={styles["remove-skill"]} onClick={() => {
                                const updatedSkills = formData.hardskills.filter((_, i) => i !== index);
                                setFormData(prevState => ({ ...prevState, hardskills: updatedSkills }));
                            }}> x</span>
                        </div>
                    ))}
                </div>
                <input type="text" placeholder="Ajouter une compétence" onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        e.preventDefault();
                        handleAddHardskill(e);
                    }
                }} />

            </div>
            <button type="submit" className={styles["button"]}>{isLast ? 'Valider' : 'Suivant'}</button>
        </form>
    );
};

export default ExperienceForm;
