import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "../styles/Register.module.css";
import { setSessionToken } from '../utils/Auth';
import { AuthContext } from "../components/AuthContext";
import { useContext } from "react";

function ValidateAccountPage() {

    const navigate = useNavigate();
    const { setIsLoggedIn } = useContext(AuthContext);

    // Get the email from the URL
    const urlParams = new URLSearchParams(window.location.search);
    const email = urlParams.get('email');

    const handleValidation = (e) => {
        e.preventDefault();
    
        fetch('https://api.wanteed-job.com/credential/validate-account', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email: email, code: document.getElementById('validation_code').value }),
        })
            .then(response => {
                if (!response.ok) {
                    alert("Invalid code");
                    return;
                }
                return response.json();
            }).then(data => {
                console.log(data);
                console.log("Login successful");
                setIsLoggedIn(true);
                localStorage.setItem('isRegistered', 'true');
                setSessionToken(data); // Store the token in cookies

                // If the user followed an invitation link, get the linkId from the local storage and send it to the API
                const linkId = localStorage.getItem('linkId');
                if (linkId) {
                    fetch('https://api.wanteed-job.com/candidate/add', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'session-key': data
                        },
                        body: JSON.stringify({ link_id: linkId })
                    })
                        .then(response => {
                            if (!response.ok) {
                                console.error('Failed to send linkId to API');
                            }
                        })
                        .catch((error) => {
                            console.error('Error sending linkId to API:', error);
                        });
                }

                // Clear the linkId from the local storage
                localStorage.removeItem('linkId');
    
                // Retrieve data from local storage
                const experiences = JSON.parse(localStorage.getItem('experiences')) || [];
                const hardSkills = JSON.parse(localStorage.getItem('hardSkills')) || [];
                const cultureTraits = JSON.parse(localStorage.getItem('cultureTraits')) || [];
                const softSkillAnswers = JSON.parse(localStorage.getItem('softskillAnswers')) || [];
    
                // Send data to API
                sendExperiencesToAPI(data, experiences);
                sendHardSkillsToAPI(data, hardSkills);
                sendCultureTraitsToAPI(data, cultureTraits);
                sendSoftSkillAnswersToAPI(data, softSkillAnswers);
    
                // Clear data from local storage
                localStorage.removeItem('experiences');
                localStorage.removeItem('hardSkills');
                localStorage.removeItem('cultureTraits');
                localStorage.removeItem('softskillAnswers');
    
                navigate('/');
            })
            .catch((error) => {
                console.error('Error:', error);
                alert('An error occurred.');
            });
    };
    
    const sendExperiencesToAPI = async (sessionKey, experiences) => {
        try {
            const response = await fetch('https://api.wanteed-job.com/experience/add', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'session-key': sessionKey
                },
                body: JSON.stringify(experiences)
            });
            if (!response.ok) {
                console.error('Failed to send experiences to API');
            }
        } catch (error) {
            console.error('Error sending experiences to API:', error);
        }
    };
    
    const sendHardSkillsToAPI = async (sessionKey, hardSkills) => {
        try {
            const response = await fetch('https://api.wanteed-job.com/job-company/add-hardskill', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'session-key': sessionKey
                },
                body: JSON.stringify(hardSkills)
            });
            if (!response.ok) {
                console.error('Failed to send hard skills to API');
            }
        } catch (error) {
            console.error('Error sending hard skills to API:', error);
        }
    };
    
    const sendCultureTraitsToAPI = async (sessionKey, cultureTraits) => {
        const body = { culture : cultureTraits.map(trait => ({ trait_id: trait.trait_id, value: trait.value })) };
        try {
            const response = await fetch('https://api.wanteed-job.com/questions/answer-culture', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'session-key': sessionKey
                    },
                    body: JSON.stringify(body)
                })
            if (!response.ok) {
                console.error('Failed to send culture traits to API');
            }
        } catch (error) {
            console.error('Error sending culture traits to API:', error);
        }
    };
    
    const sendSoftSkillAnswersToAPI = async (sessionKey, softSkillAnswers) => {
        try {
            const promises = softSkillAnswers.map(answer =>
                fetch('https://api.wanteed-job.com/questions/answer-softskill', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'session-key': sessionKey
                    },
                    body: JSON.stringify({ questionID: answer.questionID, value: answer.value, time: 0 })
                })
            );
            await Promise.all(promises);
        } catch (error) {
            console.error('Error sending soft skill answers to API:', error);
        }
    };
    


    return (
        <div className={styles["registerCard"]}>
            <div className={styles["registerForm"]}>
                <form>
                    <h1>Confirmez votre email</h1>
                    <p>Validez votre compte Wanteed en entrant le code de validation que vous avez reçu par email.</p>
                    <div className={styles["inputRow"]} style={{ marginTop: "50px" }}>
                        <input
                            type="text"
                            id="validation_code"
                            placeholder="Entrez le code de validation"
                            name="validation_code"
                        />
                    </div>
                    <div className={styles["inputRow"]}>
                        <button type="submit" onClick={handleValidation}>Valider</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default ValidateAccountPage;