import React, { useState, useEffect } from 'react';
import { Radar } from 'react-chartjs-2';
import { Chart, RadialLinearScale, PointElement, LineElement, BarElement, CategoryScale, LinearScale, Tooltip, Legend } from 'chart.js';
import { getSessionToken } from '../utils/Auth';
import styles from '../styles/PersonaPage.module.css';

Chart.register(
    RadialLinearScale,
    PointElement,
    LineElement,
    BarElement,
    CategoryScale,
    LinearScale,
    Tooltip,
    Legend
);

const PersonaPage = () => {
    const [personaData, setPersonaData] = useState([]);
    const [behaviourData, setBehaviourData] = useState([]);
    const [relationalData, setRelationalData] = useState([]);
    const [cognitiveData, setCognitiveData] = useState([]);
    const [cultureData, setCultureData] = useState([]);
    //const [mbtiData, setMbtiData] = useState([]);
    const sessionKey = getSessionToken();

    useEffect(() => {
        fetch('https://api.wanteed-job.com/softskill/get-user-personna', {
            headers: {
                'session-key': sessionKey
            }
        })
            .then(response => response.json())
            .then(data => {
                // Filter for soft skills only
                const softSkills = data.filter(trait => trait.type === "Softskill");
                setPersonaData(softSkills);

                // Filter for behaviour traits only
                const behaviourTraits = data.filter(trait => trait.type === "Comportement");
                setBehaviourData(behaviourTraits);

                console.log("data", data);

                // Filter for relational traits only
                const relationalTraits = data.filter(trait => trait.type === "Relationnel");
                setRelationalData(relationalTraits);

                // Filter for cognitive traits only
                const cognitiveTraits = data.filter(trait => trait.type === "Cognitif");
                setCognitiveData(cognitiveTraits);

                const cultureTraits = data.filter(trait => trait.type === "Culture");
                setCultureData(cultureTraits);
                console.log("cultureTraits", cultureTraits);


                // Filter for MBTI traits only
                //const mbtiTraits = data.filter(trait => trait.type === "MBTI");
                //setMbtiData(mbtiTraits);
            })
            .catch(error => console.error('Error fetching persona data:', error));
    }, [sessionKey]);

    const radarDataPersona = {
        labels: personaData.map(trait => trait.trait_name),
        datasets: [
            {
                label: 'Persona Traits',
                data: personaData.map(trait => trait.value),
                backgroundColor: 'rgba(75,192,192,0.2)',
                borderColor: 'rgba(75,192,192,1)',
                pointBackgroundColor: 'rgba(75,192,192,1)',
                pointBorderColor: '#fff',
            },
        ],
    };

    const radarOptions = {
        scales: {
            r: {
                min: 0,
                max: 10,
                ticks: {
                    stepSize: 1,
                },
            },
        },
        plugins: {
            tooltip: {
                callbacks: {
                    label: function (context) {
                        let label = context.dataset.label || '';
                        if (label) {
                            label += ': ';
                        }
                        if (context.raw !== null) {
                            label += context.raw;
                        }
                        return label;
                    },
                },
            },
            legend: {
                labels: {
                    font: {
                        size: 20, // Taille de la légende
                        weight: 'bold', // Optionnel : Rendre la légende en gras
                    },
                },
            },
        },
    };

    const radarDataBehaviour = {
        labels: behaviourData.map(trait => trait.trait_name),
        datasets: [
            {
                label: 'Behaviour Traits',
                data: behaviourData.map(trait => trait.value),
                backgroundColor: 'rgba(255,99,132,0.2)',
                borderColor: 'rgba(255,99,132,1)',
                pointBackgroundColor: 'rgba(255,99,132,1)',
                pointBorderColor: '#fff',
            },
        ],
    };

    const radarDataRelational = {
        labels: relationalData.map(trait => trait.trait_name),
        datasets: [
            {
                label: 'Relational Traits',
                data: relationalData.map(trait => trait.value),
                backgroundColor: 'rgba(255,206,86,0.2)',
                borderColor: 'rgba(255,206,86,1)',
                pointBackgroundColor: 'rgba(255,206,86,1)',
                pointBorderColor: '#fff',
            },
        ],
    };

    const radarDataCognitive = {
        labels: cognitiveData.map(trait => trait.trait_name),
        datasets: [
            {
                label: 'Cognitive Traits',
                data: cognitiveData.map(trait => trait.value),
                backgroundColor: 'rgba(54,162,235,0.2)',
                borderColor: 'rgba(54,162,235,1)',
                pointBackgroundColor: 'rgba(54,162,235,1)',
                pointBorderColor: '#fff',
            },
        ],
    };

    const radarDataCulture = {
        labels: cultureData.map(trait => trait.trait_name),
        datasets: [
            {
                label: 'Culture Traits',
                data: cultureData.map(trait => trait.value),
                backgroundColor: 'rgba(153,102,255,0.2)',
                borderColor: 'rgba(153,102,255,1)',
                pointBackgroundColor: 'rgba(153,102,255,1)',
                pointBorderColor: '#fff',
            },
        ],
    };

    
    /*

    // Prepare MBTI data for bidirectional bar graph
    const mbtiTraitPairs = [
        ['Extroverts', 'Introverts'],
        ['Feelers', 'Thinkers'],
        ['Judgers', 'Perceivers'],
        ['Intuitive', 'Sensors']
    ];

    /*
    const mbtiValuesA = mbtiTraitPairs.map(pair => {
        const trait = mbtiData.find(trait => trait.trait_name === pair[0]);
        return trait ? trait.value : 0;
    });
    const mbtiValuesB = mbtiTraitPairs.map(pair => {
        const trait = mbtiData.find(trait => trait.trait_name === pair[1]);
        return trait ? trait.value : 0;
    });

    /*
    const bidirectionalData = {
        labels: mbtiTraitPairs.map(pair => `${pair[0]} / ${pair[1]}`),
        datasets: [
            {
                label: 'Left Traits',
                data: mbtiValuesA.map(value => -value),
                backgroundColor: 'rgba(75,192,192,0.5)',
                borderColor: 'rgba(75,192,192,1)',
                borderWidth: 1,
            },
            {
                label: 'Right Traits',
                data: mbtiValuesB,
                backgroundColor: 'rgba(255,99,132,0.5)',
                borderColor: 'rgba(255,99,132,1)',
                borderWidth: 1,
            }
        ],
    };
*/
    /*
    const bidirectionalOptions = {
        indexAxis: 'y',
        scales: {
            x: {
                beginAtZero: true,
                ticks: {
                    callback: function (value) {
                        return Math.abs(value);
                    },
                },
            },
        },
        plugins: {
            tooltip: {
                callbacks: {
                    label: function (context) {
                        let label = context.dataset.label || '';
                        if (label) {
                            label += ': ';
                        }
                        if (context.raw !== null) {
                            label += Math.abs(context.raw);
                        }
                        return label;
                    },
                },
            },
        },
        maintainAspectRatio: false,
    };*/

    return (
        <div className={styles["persona-page"]}>
            <div className={styles["chart-container"]}>
                <h1>Vos qualités</h1>
                <div className={styles["radar-charts"]}>
                    <div className={styles["radar-chart"]}>
                        <Radar data={radarDataPersona} options={radarOptions} />
                    </div>
                    <div className={styles["radar-chart"]}>
                        <Radar data={radarDataBehaviour} options={radarOptions} />
                    </div>
                    <div className={styles["radar-chart"]}>
                        <Radar data={radarDataRelational} options={radarOptions} />
                    </div>
                    <div className={styles["radar-chart"]}>
                        <Radar data={radarDataCognitive} options={radarOptions} />
                    </div>
                    <div className={styles["radar-chart"]}>
                        <Radar data={radarDataCulture} options={radarOptions} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PersonaPage;
