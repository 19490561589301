import styles from '../styles/Offer.module.css';
import { useState } from 'react';
import { getSessionToken } from '../utils/Auth';

const Offer = ({ jobData }) => {
    const [active, setActive] = useState(jobData.active);

    let date = new Date(jobData.release_date);

    const formattedDescription = jobData.description.split('\n').map((item, key) => {
        return <span key={key}>{item}<br /></span>;
    });

    const handleActive = async () => {
        let newActive = !active;

        const response = await fetch('https://api.wanteed-job.com/company/activate-job', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'session-key': getSessionToken(),
            },
            body: JSON.stringify({
                company_id: jobData.company_id,
                job_id: jobData.job_id,
                active: newActive,
            }),
        });

        if (response.status !== 200) {
            console.error('Failed to activate job');
            return;
        }

        setActive(newActive);

        const container = document.querySelector(`.${styles.offerContainer}`);
        let blinkCount = 0;
        let colorBoxShadow = newActive ? '0 0 5px 2px green' : '0 0 5px 2px #666';

        // Make the border blink 3 times
        const blinkInterval = setInterval(() => {
            if (blinkCount < 6) {
                if (blinkCount % 2 === 0) {
                    container.style.boxShadow = colorBoxShadow;
                } else {
                    container.style.boxShadow = '0 0 8px rgba(0, 0, 0, 0.1)';
                }
                blinkCount++;
            } else {
                clearInterval(blinkInterval);
                container.style.boxShadow = '0 0 8px rgba(0, 0, 0, 0.1)';
            }
        }, 100); // Change color every 500ms (0.5 seconds)
    }

    return (
        <div className={styles.offerContainer}>
            <h1 className={styles.title}>{jobData.title}</h1>

            <div className={styles["section-h"]}>
                <div className={styles["section-v"]}>
                    <p><strong>Type de contrat :</strong> {jobData.type}</p>
                    <p><strong>Salaire :</strong> {parseInt(jobData.salary)}€</p>
                    <p><strong>Date de publication :</strong> {String(date.getDate()).padStart(2, '0')} / {String(date.getMonth() + 1).padStart(2, '0')} / {date.getFullYear()}</p>
                </div>

                <div className={styles["section-v"]}>
                    <p><strong>Entreprise :</strong> {jobData.company}</p>
                    <p><strong>Localisation :</strong> {jobData.city}, {jobData.country}</p>
                    <p><strong>Offre active :</strong><button className={styles["activate-button"]} onClick={handleActive}
                        style={{ borderColor: active ? 'green' : '#666', color: active ? 'green' : '#666' }}>
                        {active ? 'Oui' : 'Non'}</button></p>
                </div>
            </div>
            <div className={styles["description"]}>
                <p><strong>Description de l'offre :</strong><br /><br />{formattedDescription}</p>
            </div>
        </div>
    );
};

export default Offer;
