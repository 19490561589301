import React, { useState, useEffect } from 'react';
import { getSessionToken, setSessionToken } from '../utils/Auth';
import TestSwitcher from '../components/TestSwitcher';
import styles from '../styles/CultureFitPage.module.css';

const CultureFitPage = () => {
    const [traits, setTraits] = useState([]);
    const [token, setToken] = useState(null);
    const [currentTraitIndex, setCurrentTraitIndex] = useState(0);
    const [isReviewing, setIsReviewing] = useState(false);

    useEffect(() => {
        const token = getSessionToken();
        if (!token) {
            fetchDummyRegister();
        } else {
            setToken(token);
        }
    }, []);

    useEffect(() => {
        if (token && traits.length === 0) {
            fetch('https://api.wanteed-job.com/questions/get-culture', {
                headers: {
                    'Content-Type': 'application/json',
                    'session-key': token
                }
            })
                .then(response => response.json())
                .then(data => setTraits(data))
                .catch(error => console.error('Error fetching culture traits:', error));
        }
    }, [token, traits]);

    const fetchDummyRegister = async () => {
        try {
            const response = await fetch('https://api.wanteed-job.com/credential/get-dummy-register', {
                method: 'GET',
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setSessionToken(data);
            setToken(data);
            localStorage.setItem('dummyRegister', 'true');
        } catch (error) {
            console.error('Failed to get session key. Please try again.');
        }
    };

    const handleSliderChange = (trait, value) => {
        const updatedTraits = traits.map(t =>
            t.trait_id === trait.trait_id ? { ...t, value: value } : t
        );
        setTraits(updatedTraits);
    };

    const getEmojiForValue = (value) => {
        // Map the slider value to an emoji
        if (value < 1) {
            return "🥱";
        } else if (value < 3) {
            return "😐"; // Bored emoji
        } else if (value < 5) {
            return "😃"; // Neutral emoji
        } else if (value < 7) {
            return "😍"; // Heart eyes emoji
        } else {
            return "🤩"; // Star eyes emoji
        }
    };

    const handleReviewAnswers = () => {
        setIsReviewing(true);
        setCurrentTraitIndex(0);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!isReviewing) {
            handleReviewAnswers();
            return;
        }

        let cultureBody = [];
        traits.forEach(trait => {
            cultureBody.push({
                trait_id: trait.trait_id,
                value: trait.value
            });
        });

        try {
            const response = await fetch('https://api.wanteed-job.com/questions/answer-culture', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'session-key': token
                },
                body: JSON.stringify({
                    culture: cultureBody
                })
            })
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            window.location.href = '/softSkills';
        }
        catch (error) {
            console.error('Failed to submit culture traits:', error);
        }

    };

    return (
        <div className={styles["culture-fit-page"]}>
            <TestSwitcher activeTest="culturefit" />
            <h1 className='h1'>Vos intérêts</h1>
            <p className='p'>Évaluez à quel point chacun des critères suivants sont importants pour vous dans un environnement de travail.</p>
            <form onSubmit={handleSubmit} className='form'>
                {isReviewing ? (
                    traits.map(trait => (
                        <div key={trait.trait_id} className={styles["trait-container"]}>
                            <label className={styles["trait-label"]}>{trait.trait_name}</label>
                            <input
                                type="range"
                                min="0"
                                max="7"
                                value={trait.value}
                                onChange={(e) => handleSliderChange(trait, e.target.value)}
                                className="trait-slider"
                                style={{ background: `linear-gradient(to right, #ddd ${trait.value * 14.2857}%, #007bff ${trait.value * 14.2857}%)` }}
                            />
                            <div className={styles["trait-value"]}>{getEmojiForValue(trait.value)}</div>
                        </div>
                    ))
                ) : (
                    <>
                        {traits.length > 0 && (
                            <div key={traits[currentTraitIndex].trait_id} className={styles["trait-container"]}>
                                <div className={styles["top-bar"]}>
                                    <button type="button" onClick={() => setCurrentTraitIndex(currentTraitIndex - 1)} disabled={currentTraitIndex === 0} className={styles["prev-button"]}>←</button>
                                    <div className={styles["trait-index"]}>{currentTraitIndex + 1} / {traits.length}</div>
                                    <button type="button" onClick={() => setCurrentTraitIndex(currentTraitIndex + 1)} disabled={currentTraitIndex === traits.length - 1} className={styles["next-button"]}>→</button>
                                </div>
                                <label className={styles["trait-label"]}>{traits[currentTraitIndex].trait_name}</label>
                                <input
                                    type="range"
                                    min="0"
                                    max="7"
                                    value={traits[currentTraitIndex].value}
                                    onChange={(e) => handleSliderChange(traits[currentTraitIndex], e.target.value)}
                                    className="trait-slider"
                                    style={{ background: `linear-gradient(to right, #ddd ${traits[currentTraitIndex].value * 14.2857}%, #007bff ${traits[currentTraitIndex].value * 14.2857}%)` }}
                                />
                                <div className={styles["trait-value"]}>{getEmojiForValue(traits[currentTraitIndex].value)}</div>
                            </div>
                        )}
                    </>
                )}
                {(currentTraitIndex === traits.length - 1 || isReviewing) && (
                    <button type="submit" className={styles["submit-button"]}>{isReviewing ? "Soumettre" : "Page suivante"}</button>
                )}
            </form>
        </div>
    );
};

export default CultureFitPage;
