import React, { useState } from "react";
import styles from "../styles/Register.module.css";

function ResetPassword() {
    const [email, setEmail] = useState('');
    const [code, setCode] = useState('');
    const [password, setPassword] = useState('');

    const handleResetPassword = (e) => {
        e.preventDefault();
        fetch('https://api.wanteed-job.com/credential/reset-password', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email: email, code: code, password: password }),
        })
            .then(response => response.json())
            .then(data => {
                if (data.error) {
                    alert(data.error);
                } else {
                    alert('Password reset successfully!');
                }
            })
            .catch((error) => {
                console.error('Error:', error);
                alert('An error occurred.');
            });
    }

    const handleSendCode = (e) => {
        fetch(`https://api.wanteed-job.com/credential/send-reset-password-code?email=${email}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => response.json())
            .then(data => {
                if (data.error) {
                    alert(data.error);
                } else {
                    alert('Code sent successfully!');
                }
            })
            .catch((error) => {
                console.error('Error:', error);
                alert('An error occurred.');
            }
            );
    }

    return (
        <div className={styles["registerCard"]}>
            <h1>Réinitialiser votre mot de passe</h1>
            <p>Cliquez sur le bouton ci-dessous pour recevoir un code de validation par email.</p>
            <div className={styles["registerForm"]}>
                <form onSubmit={handleSendCode}>
                    <div className={styles["inputRow"]}>

                        <label htmlFor="email">Email</label>
                        <input
                            type="email"
                            id="email"
                            name="email_value"
                            placeholder="Entrez votre email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                </form>
            </div>
            <button type="submit" onClick={handleSendCode}>Envoyer le code</button>
            <p>Entrez le code de validation reçu par email et votre nouveau mot de passe.</p>
            <div className={styles["registerForm"]}>
                <form onSubmit={handleResetPassword}>
                    <div className={styles["inputRow"]}>
                        <label htmlFor="reset_code">Code</label>
                        <input
                            type="text"
                            id="reset_code"
                            name="reset_code"
                            placeholder="Entrez le code de validation"
                            value={code}
                            onChange={(e) => setCode(e.target.value)}
                        />
                    </div>
                    <div className={styles["inputRow"]}>
                        <label htmlFor="password">Nouveau mot de passe</label>
                        <input
                            type="password"
                            id="password"
                            name="password"
                            placeholder="Entrez votre nouveau mot de passe"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                    <div className={styles["inputRow"]}>
                        <button type="submit">Valider</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default ResetPassword;