import React, { useEffect, useState, useContext } from 'react';
import EditProfile from '../pages/EditProfile';
import { getSessionToken, removeSessionToken } from '../utils/Auth';
import styles from "../styles/Profile.module.css"
import { useNavigate } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import Modal from './Modal';

const Profile = () => {
    const [userData, setUserData] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const sessionKey = getSessionToken();
    const { setIsLoggedIn } = useContext(AuthContext);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        fetch('https://api.wanteed-job.com/user/get-information', {
            method: 'GET',
            headers: { 'session-key': sessionKey }
        })
            .then(response => response.json())
            .then(data => {
                data.birth_date = new Date(data.birth_date).toLocaleDateString();
                setUserData(data);
            })
            .catch(error => console.error('Error fetching user data:', error));
    }, [sessionKey]);

    if (!userData) {
        return <div>Loading...</div>;
    }

    const handleEditComplete = () => {
        console.log('Edit complete');
        setIsEditing(false);
        fetch('https://api.wanteed-job.com/user/get-information', {
            method: 'GET',
            headers: { 'session-key': sessionKey }
        })
            .then(response => response.json())
            .then(data => {
                data.birth_date = new Date(data.birth_date).toLocaleDateString();
                setUserData(data);
            })
            .catch(error => console.error('Error fetching user data:', error));
    };

    if (isEditing) {
        return <EditProfile onEditComplete={handleEditComplete}/>;
    }

    const handleLogout = () => {
        removeSessionToken();
        setIsLoggedIn(false);
        navigate('/login');
    };

    const handlechangepassword = () => {
        fetch('https://api.wanteed-job.com/user/change-password', {
            method: 'PUT',
            headers: { 'session-key': sessionKey, 'Content-Type': 'application/json' },
            body: JSON.stringify({
                old_password: document.getElementById('oldpassword').value,
                new_password: document.getElementById('newpassword').value
            })
        })
            .then(response => {
                if (response.status === 401) {
                    removeSessionToken();
                    setIsLoggedIn(false);
                    navigate('/login');
                } else if (response.status === 403) {
                    document.getElementById('oldpassword').style.color = 'red';
                    document.getElementById('oldpassword').style.borderColor = 'red';
                }
                return response.json();
            })
            .then(data => {
                console.log(data);
            })
            .catch(error => console.error('Error changing password:', error));
    };

    const handleDeleteAccount = () => {
        fetch('https://api.wanteed-job.com/user/delete-account', {
            method: 'DELETE',
            headers: { 'session-key': sessionKey }
        })
            .then(response => {
                if (response.status === 401) {
                    removeSessionToken();
                    setIsLoggedIn(false);
                    navigate('/login');
                }
                return response.json();
            })
            .then(data => {
                console.log(data);
                removeSessionToken();
                setIsLoggedIn(false);
                navigate('/login');
            })
            .catch(error => console.error('Error deleting account:', error));
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <div className={styles.profileContainer}>
            <h1 className={styles.profileTitle}>Informations du profil</h1>
            <div className={styles.profileList}>
                <div className={styles.profileItem}><strong>Nom :</strong> <span>{userData.last_name}</span></div>
                <div className={styles.profileItem}><strong>Prénom :</strong> <span>{userData.first_name}</span></div>
                <div className={styles.profileItem}><strong>Civilité :</strong> <span>{userData.civility}</span></div>
                <div className={styles.profileItem}><strong>Pays :</strong> <span>{userData.country}</span></div>
                <div className={styles.profileItem}><strong>Téléphone :</strong> <span>{userData.phone}</span></div>
                <div className={styles.profileItem}><strong>Adresse :</strong> <span>{userData.address}</span></div>
                <div className={styles.profileItem}><strong>Ville :</strong> <span>{userData.city}</span></div>
                <div className={styles.profileItem}><strong>Newsletter :</strong> <span>{userData.newsletter ? 'Abonné' : 'Non Abonné'}</span></div>
                <div className={styles.profileItem}><strong>Date de naissance :</strong> <span>{userData.birth_date}</span></div>
                <button className={styles.editButton} onClick={() => setIsEditing(true)}>Modifier</button>
            </div>
            <div className={styles.separator} />
            <h1 className={styles.profileTitle}>Informations de connexion</h1>
            <div className={styles.profileList}>
                <div className={styles.profileItem}><strong>Email :</strong> <span>{userData.email}</span></div>
                <div className={styles.profileItem}><strong>Ancien mot de passe :</strong><input type="password" id='oldpassword' /></div>
                <div className={styles.profileItem}><strong>Nouveau mot de passe :</strong><input type="password" id='newpassword' /></div>
                <button className={styles.editButton} onClick={() => handlechangepassword()}>Changer mot de passe</button>
            </div>
            <div className={styles.separator} />
            <div className={styles.profileList}>
                <button className={styles.disconnectButton} onClick={handleLogout}>Déconnexion</button>
                <button className={styles.disconnectButton} style={{ marginTop: '20px' }} onClick={() => setIsModalOpen(true)}>Supprimer le compte</button>
            </div>
            <Modal
                    isOpen={isModalOpen}
                    onClose={closeModal}
                    contentLabel="confirm-delete-account"
                    className={styles["modal"]}
                    overlayClassName={styles["overlay"]}
                >
                    <h2 className={styles["modal-title"]}>Vous êtes sûr de vouloir supprimer votre compte ?</h2>
                    <div className={styles["modal-text"]}>Cette action est irréversible.</div>
                    <div className={styles["modal-buttons"]}>
                    <button onClick={handleDeleteAccount} style={{ backgroundColor: '#dc3545' }}>Oui</button>
                    <button onClick={closeModal}>Non</button>
                    </div>
                </Modal>
        </div >
    );
};

export default Profile;