import React, { useState, useEffect } from 'react';
import { Radar } from 'react-chartjs-2';
import { Chart, RadialLinearScale, PointElement, LineElement, BarElement, CategoryScale, LinearScale, Tooltip, Legend } from 'chart.js';
import { getSessionToken } from '../utils/Auth';
import { useLocation } from 'react-router-dom';
import styles from '../styles/CandidatePersonnaPage.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import Tabs from '../components/Tabs';
import Panel from '../components/Panel';

Chart.register(
    RadialLinearScale,
    PointElement,
    LineElement,
    BarElement,
    CategoryScale,
    LinearScale,
    Tooltip,
    Legend
);

const CandidatePersonaPage = () => {
    const [personaData, setPersonaData] = useState([]);
    const [behaviorData, setBehaviorData] = useState([]);
    const [relationalData, setRelationalData] = useState([]);
    const [cognitiveData, setCognitiveData] = useState([]);
    const [cultureData, setCultureData] = useState([]);
    // const [mbtiData, setMbtiData] = useState([]);

    const [hardskillData, setHardskillData] = useState([]);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: null });

    const [experienceData, setExperienceData] = useState([]);
    const sessionKey = getSessionToken();
    const [candidateName, setCandidateName] = useState('');
    const [email, setEmail] = useState('');
    const [linkedin, setLinkedin] = useState('');
    const [phone, setPhone] = useState('');
    const [sortedHardSkills, setSortedHardskills] = useState([]);

    const [idealPersonna, setIdealPersonna] = useState([]);

    const location = useLocation();
    const company_id = location.state.company_id;
    const job_id = location.state.job_id;

    const candidateID = window.location.pathname.split('/').pop();

    useEffect(() => {
        fetch(`https://api.wanteed-job.com/job-company/get-personna?company_id=${company_id}&job_id=${job_id}`, {
            headers: {
                'Content-Type': 'application/json',
                'session-key': sessionKey
            }
        })
            .then(response => response.json())
            .then(data => {
                console.log("ideal personna data", data);
                setIdealPersonna(data["personna"]);
            })
            .catch(error => console.error('Error fetching ideal personna data:', error));
    }, [sessionKey, company_id, job_id]);

    useEffect(() => {
        if (!sessionKey || !candidateID) {
            console.error("Missing sessionKey or candidateID");
            return;
        }

        fetch(`https://api.wanteed-job.com/candidate/get-info?candidate_id=${candidateID}&company_id=${company_id}&job_id=${job_id}`, {
            headers: {
                'Content-Type': 'application/json',
                'session-key': sessionKey
            }
        })
            .then(response => response.json())
            .then(data => {
                setCandidateName(data.first_name + ' ' + data.last_name);
                setEmail(data.email);
                setLinkedin(data.linkedin);
                setPhone("");

                const softSkills = data.personna.filter(trait => ['B', 'C', 'R'].includes(trait.trait_id));
                setPersonaData(softSkills);

                const behaviorData = data.personna.filter(trait => parseInt(trait.trait_id) >= 13 && parseInt(trait.trait_id) <= 21);
                setBehaviorData(behaviorData);

                const relationalTraits = data.personna.filter(trait => parseInt(trait.trait_id) >= 22 && parseInt(trait.trait_id) <= 31);
                setRelationalData(relationalTraits);

                const cognitiveTraits = data.personna.filter(trait => parseInt(trait.trait_id) >= 32 && parseInt(trait.trait_id) <= 39);
                setCognitiveData(cognitiveTraits);

                // const mbtiTraits = data.personna.filter(trait => ['E', 'F', 'I', 'J', 'N', 'P', 'S', 'T'].includes(trait.trait_id));
                // setMbtiData(mbtiTraits);

                const cultureTraits = data.personna.filter(trait => parseInt(trait.trait_id) >= 1 && parseInt(trait.trait_id) <= 12);
                setCultureData(cultureTraits);

                const hardskillData = data.hardskill;
                setHardskillData(hardskillData);

                const sortedData = hardskillData.sort((a, b) => {
                    return b.time - a.time;
                });
                setSortConfig({ key: "time", direction: "desc" });
                setSortedHardskills(sortedData);

                const experienceData = data.experience;
                setExperienceData(experienceData);
            })
            .catch(error => console.error('Error fetching persona data:', error));
    }, [sessionKey, candidateID, company_id, job_id]);


    const radarDataPersona = {
        labels: personaData.length > 0 ? personaData.map(trait => trait.trait_name) : [],
        datasets: [
            {
                label: personaData.length > 0 ? personaData[0].type : '',
                data: personaData.length > 0 ? personaData.map(trait => trait.value) : [],
                backgroundColor: 'rgba(75,192,192,0.2)',
                borderColor: 'rgba(75,192,192,1)',
                pointBackgroundColor: 'rgba(75,192,192,1)',
                pointBorderColor: '#fff',
            },
            {
                label: "Idéal",
                data: idealPersonna.length > 0 ? idealPersonna.filter(trait => trait.type === "Softskill").map(trait => trait.value) : [],
            }
        ],
    };

    const radarDataBehaviour = {
        labels: behaviorData.length > 0 ? behaviorData.map(trait => trait.trait_name) : [],
        datasets: [
            {
                label: behaviorData.length > 0 ? behaviorData[0].type : '',
                data: behaviorData.length > 0 ? behaviorData.map(trait => trait.value) : [],
                backgroundColor: 'rgba(255,99,132,0.2)',
                borderColor: 'rgba(255,99,132,1)',
                pointBackgroundColor: 'rgba(255,99,132,1)',
                pointBorderColor: '#fff',
            },
            {
                label: "Idéal",
                data: idealPersonna.length > 0 ? idealPersonna.filter(trait => trait.type === "Behavior").map(trait => trait.value) : [],

            }
        ],
    };

    const radarDataRelational = {
        labels: relationalData.length > 0 ? relationalData.map(trait => trait.trait_name) : [],
        datasets: [
            {
                label: relationalData.length > 0 ? relationalData[0].type : '',
                data: relationalData.length > 0 ? relationalData.map(trait => trait.value) : [],
                backgroundColor: 'rgba(255,206,86,0.2)',
                borderColor: 'rgba(255,206,86,1)',
                pointBackgroundColor: 'rgba(255,206,86,1)',
                pointBorderColor: '#fff',
            },
            {
                label: "Idéal",
                data: idealPersonna.length > 0 ? idealPersonna.filter(trait => trait.type === "Relational").map(trait => trait.value) : [],
            }
        ],
    };

    const radarDataCognitive = {
        labels: cognitiveData.length > 0 ? cognitiveData.map(trait => trait.trait_name) : [],
        datasets: [
            {
                label: cognitiveData.length > 0 ? cognitiveData[0].type : '',
                data: cognitiveData.length > 0 ? cognitiveData.map(trait => trait.value) : [],
                backgroundColor: 'rgba(54,162,235,0.2)',
                borderColor: 'rgba(54,162,235,1)',
                pointBackgroundColor: 'rgba(54,162,235,1)',
                pointBorderColor: '#fff',
            },
            {
                label: "Idéal",
                data: idealPersonna.length > 0 ? idealPersonna.filter(trait => trait.type === "Cognitive").map(trait => trait.value) : [],
            }
        ],
    };

    const radarDataCulture = {
        labels: cultureData.length > 0 ? cultureData.map(trait => trait.trait_name) : [],
        datasets: [
            {
                label: cultureData.length > 0 ? cultureData[0].type : '',
                data: cultureData.length > 0 ? cultureData.map(trait => trait.value) : [],
                backgroundColor: 'rgba(153,102,255,0.2)',
                borderColor: 'rgba(153,102,255,1)',
                pointBackgroundColor: 'rgba(153,102,255,1)',
                pointBorderColor: '#fff',
            },
            {
                label: "Idéal",
                data: idealPersonna.length > 0 ? idealPersonna.filter(trait => trait.type === "Culture").map(trait => trait.value) : [],
            }
        ],
    };

    const radarOptions = {
        scales: {
            r: {
                min: 0,
                max: 10,
                ticks: {
                    stepSize: 1,
                },
            },
        },
        plugins: {
            tooltip: {
                callbacks: {
                    label: function (context) {
                        let label = context.dataset.label || '';
                        if (label) {
                            label += ': ';
                        }
                        if (context.raw !== null) {
                            label += context.raw;
                        }
                        return label;
                    },
                },
            },
            legend: {
                labels: {
                    font: {
                        size: 20, // Taille de la légende
                        weight: 'bold', // Optionnel : Rendre la légende en gras
                    },
                },
            },
        },
    };

    function formatTimeInMonths(months) {
        const years = Math.floor(months / 12);
        const remainingMonths = months % 12;
        if (years > 0 && remainingMonths > 0) {
            return `${years} an${years > 1 ? 's' : ''} et ${remainingMonths} mois`;
        }
        if (years > 0) {
            return `${years} an${years > 1 ? 's' : ''}`;
        }
        return `${months} mois`;
    }

    const sortColumn = (key) => {
        // Alterner entre ascendant et descendant 
        let direction = "asc";
        if (sortConfig.key === key) {
            if (sortConfig.direction === "asc") direction = "desc";
            else if (sortConfig.direction === "desc") {
                direction = null;
                console.log("reset");
                setSortedHardskills(hardskillData); // Revenir à l'ordre initial
                setSortConfig({ key: null, direction: null }); // Reset du tri
                return;
            }
        }
        setSortConfig({ key, direction });

        // si la clé est "time", on trie par durée
        if (key === "time") {
            const sortedData = hardskillData.sort((a, b) => {
                if (direction === "asc") {
                    return a.time - b.time;
                } else {
                    return b.time - a.time;
                }
            });
            setSortedHardskills(sortedData);
        } else if (key === "hardskill") {
            const sortedData = hardskillData.sort((a, b) => {
                if (direction === "desc") {
                    return a.hardskill.localeCompare(b.hardskill);
                } else {
                    return b.hardskill.localeCompare(a.hardskill);
                }
            });
            setSortedHardskills(sortedData);
        }
    };

    // const mbtiTraitPairs = [
    //     ['E', 'I'],
    //     ['S', 'N'],
    //     ['T', 'F'],
    //     ['J', 'P'],
    // ];

    // const mbtiTraitPairsLabels = mbtiTraitPairs.map(pair => {
    //     const traitA = mbtiData.find(trait => trait.trait_id === pair[0]);
    //     const traitB = mbtiData.find(trait => trait.trait_id === pair[1]);

    //     if (!traitA || !traitB) {
    //         return '';
    //     }

    //     return `${traitA.trait_name} / ${traitB.trait_name}`;
    // });

    // const mbtiValuesA = mbtiTraitPairs.map(pair => {
    //     const trait = mbtiData.find(trait => trait.trait_id === pair[0]);
    //     return trait ? trait.value : 0;
    // });
    // const mbtiValuesB = mbtiTraitPairs.map(pair => {
    //     const trait = mbtiData.find(trait => trait.trait_id === pair[1]);
    //     return trait ? trait.value : 0;
    // });

    // const bidirectionalData = {
    //     labels: mbtiTraitPairsLabels,
    //     datasets: [
    //         {
    //             data: mbtiValuesA.map(value => -value),
    //             backgroundColor: 'rgba(75,192,192,0.5)',
    //             borderColor: 'rgba(75,192,192,1)',
    //             borderWidth: 1,
    //         },
    //         {
    //             data: mbtiValuesB,
    //             backgroundColor: 'rgba(255,99,132,0.5)',
    //             borderColor: 'rgba(255,99,132,1)',
    //             borderWidth: 1,
    //         }
    //     ],
    // };


    // const bidirectionalOptions = {
    //     indexAxis: 'y',
    //     scales: {
    //         x: {
    //             min: -10,
    //             max: 10,
    //             beginAtZero: true,
    //             ticks: {
    //                 stepSize: 1,
    //             },
    //         },
    //         y: {
    //             stacked: true,
    //             ticks: {
    //                 stepSize: 1,
    //             },
    //         },
    //     },
    //     plugins: {
    //         tooltip: {
    //             callbacks: {
    //                 label: function (context) {
    //                     let label = context.dataset.label || '';
    //                     if (label) {
    //                         label += ': ';
    //                     }
    //                     if (context.raw !== null) {
    //                         label += Math.abs(context.raw);
    //                     }
    //                     return label;
    //                 },
    //             },
    //         },
    //         legend: {
    //             display: false, // Pour enlever la légende
    //         },
    //     },
    // };

    return (
        <div className={styles["persona-page"]}>
            <div className={styles["candidate-header"]}>
                <div className={styles['back-button']}>
                    <button onClick={() => window.history.back()}>
                        <FontAwesomeIcon icon={faArrowLeft} /> Retour à l'offre
                    </button>
                </div>
            </div>
            <div className={styles["candidate-info"]}>
                <p className={styles['candidatepersonna-candidate-name']}>{candidateName}</p>
            </div>
            <Tabs>
                <Panel title="Expériences du candidats">
                    <div className={styles["experience"]}>
                        <h2>{experienceData.length > 1 ? 'Expériences' : 'Expérience'} du candidat</h2>
                        <div className={styles["experience-list"]}>
                            {experienceData.map((experience, index) => (
                                <div key={index} className={styles["experience-item"]}>
                                    <div className={styles["experience-item-head"]}>
                                        <p>{experience.profession} chez {experience.company}</p>
                                        <p>{formatTimeInMonths(experience.duration)}</p>
                                    </div>
                                    <p>{experience.description}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </Panel>
                <Panel title="Compétences du candidat">
                    <div className={styles["hardskills"]}>
                        <h2>{hardskillData.length > 1 ? 'Compétences' : 'Compétence'} du candidat</h2>
                        <table className={styles["hardskills-table"]}>
                            <thead>
                                <tr>
                                    <th
                                        onClick={() => sortColumn("hardskill")}
                                        className={
                                            sortConfig.key === "hardskill"
                                                ? sortConfig.direction === "asc"
                                                    ? styles["sorted-asc"]
                                                    : styles["sorted-desc"]
                                                : ""
                                        }
                                    >
                                        Compétences
                                    </th>
                                    <th
                                        onClick={() => sortColumn("time")}
                                        className={
                                            sortConfig.key === "time"
                                                ? sortConfig.direction === "asc"
                                                    ? styles["sorted-asc"]
                                                    : styles["sorted-desc"]
                                                : ""
                                        }
                                    >
                                        Durée
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {Array.isArray(sortedHardSkills) &&
                                    hardskillData.map((hardskill, index) => (
                                        <tr key={index} className={styles["hardskills-row"]}>
                                            <td>{hardskill.hardskill}</td>
                                            <td>{formatTimeInMonths(hardskill.time)}</td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                </Panel>
                <Panel title="Qualités du candidat">
                    <div className={styles["persona-analysis"]}>
                        <h2>Qualités du candidat</h2>
                        <div className={styles["radar-charts"]}>
                            <div className={styles["radar-chart"]}>
                                <Radar data={radarDataPersona} options={radarOptions} />
                            </div>
                            <div className={styles["radar-chart"]}>
                                <Radar data={radarDataBehaviour} options={radarOptions} />
                            </div>
                            <div className={styles["radar-chart"]}>
                                <Radar data={radarDataRelational} options={radarOptions} />
                            </div>
                            <div className={styles["radar-chart"]}>
                                <Radar data={radarDataCognitive} options={radarOptions} />
                            </div>
                            <div className={styles["radar-chart"]}>
                                <Radar data={radarDataCulture} options={radarOptions} />
                            </div>
                        </div>
                    </div>
                </Panel>
                <Panel title="Contact">
                    <div className={styles["contact"]}>
                        <h2>Contact</h2>
                        <div className={styles["contact-divider"]}><strong>Telephone:</strong> {phone}</div>
                        <div className={styles["contact-divider"]}><strong>Email:</strong> {email}</div>
                        <button className={styles["contact-button"]}><a href={linkedin} target="_blank" rel="noreferrer">Voir le profil LinkedIn</a></button>
                    </div>
                </Panel>
            </Tabs>
        </div>
    );
};

export default CandidatePersonaPage;